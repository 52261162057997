<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">CV</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12">
            <iframe :src="url" width="100%" height="100%">
              Este navegador no admite archivos PDF. Descargue el PDF para verlo: <a href="/test.pdf">Download PDF</a>
            </iframe>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-small" :disabled="loading" @click="close">Cerrar</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalNuevoUsuario',
  props: {
    url: {
      type: String,
      default: ''
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.divider {
  margin: 0.5rem 0;
}
.field {
  .label {
    font-size: 13px;
    text-align: left;
    margin-left: 0.2rem;
    font-weight: 500;
  }
}
.modal-card {
  width: 80%;
}
iframe {
  height: 70vh;;
}
</style>
